
import { defineComponent, ref } from "vue";
import {
  modalController,
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonTitle,
  IonLabel,
  IonIcon,
  IonButtons,
} from "@ionic/vue";
import { heart, closeOutline } from "ionicons/icons";

import ProfileHeder from "@/components/profile/show/ProfileHeader.vue";
import ProfileBody from "@/components/profile/show/ProfileBodyC.vue";
import ProfileSkeleton from "@/components/profile/show/ProfileSkeleton.vue";

import { loadProfileByUid } from "@/components/profile/show";
import { sendLike, sendDislike } from "@/components/suggestions";
import { listOfLikes } from "@/components/likes";

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButton,
    IonLabel,
    IonIcon,
    IonTitle,
    IonButtons,
    ProfileHeder,
    ProfileBody,

    ProfileSkeleton,
  },
  props: {
    uid: String,
  },
  setup(props: any) {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const profile = ref<any | null>({});
    const photos = ref<string[]>([]);
    const loadingProfile = ref(true);
    const errorOccured = ref(false);
    const loadLikesProfile = async () => {
      loadingProfile.value = true;
      try {
        if (props.uid == null) {
          errorOccured.value = true;
        }
        const result = await loadProfileByUid(props.uid);

        if (result != null) {
          profile.value = result.profile;
          photos.value = result.allPhotos;
        } else {
          profile.value = null;
        }
      } catch (err) {
        errorOccured.value = true;
      } finally {
        loadingProfile.value = false;
      }
    };
    loadLikesProfile();

    const actionSpinnerYes = ref(false);
    const actionSpinnerNo = ref(false);
    const likeToBeSend = async (partnerUid: string) => {
      actionSpinnerYes.value = true;
      delete listOfLikes.value[partnerUid];
      await sendLike(partnerUid);
      closeModal();
    };

    const dislikeToBeSend = async (partnerUid: string) => {
      actionSpinnerNo.value = true;
      delete listOfLikes.value[partnerUid];
      await sendDislike(partnerUid, true);
      closeModal();
    };

    return {
      actionSpinnerYes,
      actionSpinnerNo,
      likeToBeSend,
      dislikeToBeSend,
      loadingProfile,
      profile,
      photos,
      closeModal,
      errorOccured,

      heart,
      closeOutline,
    };
  },
});
