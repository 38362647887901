<template>
  <ion-page>
    <ion-header>
      <ion-toolbar :collapse="true">
        <ion-title>{{ $t("tabs.likes") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content slot="fixed">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $t("tabs.likes") }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div v-if="loadingListOfLikes">
        <LikesSkeleton />
      </div>
      <div v-else>
        <div v-if="Object.keys(listOfLikes).length == 0">
          <div style="text-align: center; margin: 2rem">
            <!-- <ion-icon
              :icon="alertCircleOutline"
              style="font-size: 64px"
            ></ion-icon> -->
             <ion-img style="width:200px; margin:auto;" src="/assets/empty_states/likes.png"></ion-img>
            <h6 style="margin-top: 6px">{{ $t("likes.noLikes") }}</h6>
          </div>
        </div>
        <div style="margin: auto; margin-top: 15px">
          <ion-grid fixed="true" style="--ion-grid-width: 900px">
            <ion-row>
              <ion-col
                :size-xs="6"
                size-md="3"
                v-for="(profile, uid) in listOfLikes"
                :key="uid"
                style="padding: 10px"
              >
                <ion-card
                  class="cardOfLover"
                  mode="ios"
                  @click="openLikerProfile(uid)"
                >
                  <ion-img
                    v-if="profile.profilePhoto"
                    style="
                      height: 120px;
                      object-fit: cover;
                      border-radius: 25px 25px 0px 0px;
                    "
                    :src="profile.profilePhoto"
                  />
                  <div
                    v-else
                    style="
                      height: 120px;
                      text-align: center;
                      width: 100%;
                      background-color: #aaa;
                      border-radius: 25px 25px 0px 0px;
                    "
                  >
                    <ion-icon
                      size="large"
                      :icon="personOutline"
                      style="height: 100%; color: white"
                    />
                  </div>

                  <ion-card-header
                    style="
                      padding-bottom: 0.8rem;
                      padding-top: 0.8rem;
                    "
                  >
                    <ion-card-title>{{ profile.name }}</ion-card-title>
                  </ion-card-header>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  isPlatform,
} from "@ionic/vue";
import {
  locationOutline,
  alertCircleOutline,
  personOutline,
  briefcaseOutline,
} from "ionicons/icons";

import { loadingListOfLikes, listOfLikes } from "@/components/likes";

import LikerProfileModal from "@/components/likes/LikerProfileModal.vue";
import LikesSkeleton from "@/components/likes/LikesSkeleton.vue";

export default defineComponent({
  name: "LikesTab",
  components: {
    LikesSkeleton,
    IonPage,
    IonHeader,
    IonIcon,
    IonToolbar,
    IonTitle,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    // loadLikes(); // called from Tabs.vue

    const openLikerProfile = async (uid: string) => {
      const modal = await modalController.create({
        component: LikerProfileModal,
        cssClass: isPlatform("desktop") ? "desktopModalClass" : "",
        componentProps: { uid: uid },
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      alertCircleOutline,
      openLikerProfile,
      locationOutline,
      listOfLikes,
      loadingListOfLikes,
      personOutline,
      briefcaseOutline,
    };
  },
});
</script>

<style scoped>
.cardOfLover {
  cursor: pointer;
  margin: auto;
  border-radius: 25px;
  width: inherit;
  max-width: 250px;
}

.cardOfLover:hover {
  transition: all 0.2s ease-in-out;
  transform: translateY(-4px);
}

.cardOfLover:active {
  transition: all 0.2s ease;
  transform: translateY(+2px);
  box-shadow: none;
}
</style>