<template>
  <div style="margin: auto">
    <ion-grid fixed="true" style="--ion-grid-width: 900px">
      <ion-row>
        <ion-col
          :size-xs="6"
          size-md="3"
          v-for="index in 7"
          :key="index"
          style="padding: 10px"
        >
          <ion-card class="cardOfLover" mode="ios">
            <div
              style="
                height: 120px;
                text-align: center;
                width: 100%;
                border-radius: 25px 25px 0px 0px;
              "
            ></div>

            <ion-card-header
              style="
                padding-bottom: 0.8rem;
                padding-top: 0.8rem;
              "
            >
              <ion-card-title>
                <ion-skeleton-text
                  animated
                  style="line-height: 1.5rem; width: 80%"
                ></ion-skeleton-text
              ></ion-card-title>
            </ion-card-header>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import {
  IonSkeletonText,
  IonCardTitle,
  IonCardHeader,
  IonCard,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonSkeletonText,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    IonCol,
    IonRow,
    IonGrid,
  },
});
</script>

<style scoped>
ion-skeleton-text {
  border-radius: 20px;
}

.cardOfLover {
  cursor: pointer;
  margin: auto;
  border-radius: 25px;
  width: inherit;
  max-width: 250px;
}
</style>
