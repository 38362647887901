<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title v-if="profile != null">{{ profile.name }}</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="closeModal()">Zatvoriť</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div v-if="loadingProfile">
        <ProfileSkeleton />
      </div>
      <div v-else>
        <div v-if="profile == null || errorOccured">
          {{ $t("errors.problemOccured") }}
        </div>
        <div v-else>
          <ProfileHeder :profile="profile" :allPhotos="photos" />
          <div style="text-align: center; margin-bottom: 2rem">
            <ion-button
              @click="dislikeToBeSend(profile.uid)"
              shape="round"
              color="light"
              style="width: 120px; height: 3rem"
              :disabled="actionSpinnerNo || actionSpinnerYes ? 'true' : 'false'"
              ><ion-icon slot="start" :icon="closeOutline" />
              <ion-label>{{ $t("buttons.no") }}</ion-label>
            </ion-button>
            <ion-button
              @click="likeToBeSend(profile.uid)"
              shape="round"
              color="primary"
              style="width: 120px; height: 3rem"
              :disabled="actionSpinnerNo || actionSpinnerYes ? 'true' : 'false'"
            >
              <ion-icon slot="start" :icon="heart" />
              <ion-label>{{ $t("buttons.yes") }}</ion-label>
            </ion-button>
          </div>
          <ProfileBody :profile="profile" :allPhotos="photos" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  modalController,
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonTitle,
  IonLabel,
  IonIcon,
  IonButtons,
} from "@ionic/vue";
import { heart, closeOutline } from "ionicons/icons";

import ProfileHeder from "@/components/profile/show/ProfileHeader.vue";
import ProfileBody from "@/components/profile/show/ProfileBodyC.vue";
import ProfileSkeleton from "@/components/profile/show/ProfileSkeleton.vue";

import { loadProfileByUid } from "@/components/profile/show";
import { sendLike, sendDislike } from "@/components/suggestions";
import { listOfLikes } from "@/components/likes";

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButton,
    IonLabel,
    IonIcon,
    IonTitle,
    IonButtons,
    ProfileHeder,
    ProfileBody,

    ProfileSkeleton,
  },
  props: {
    uid: String,
  },
  setup(props: any) {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const profile = ref<any | null>({});
    const photos = ref<string[]>([]);
    const loadingProfile = ref(true);
    const errorOccured = ref(false);
    const loadLikesProfile = async () => {
      loadingProfile.value = true;
      try {
        if (props.uid == null) {
          errorOccured.value = true;
        }
        const result = await loadProfileByUid(props.uid);

        if (result != null) {
          profile.value = result.profile;
          photos.value = result.allPhotos;
        } else {
          profile.value = null;
        }
      } catch (err) {
        errorOccured.value = true;
      } finally {
        loadingProfile.value = false;
      }
    };
    loadLikesProfile();

    const actionSpinnerYes = ref(false);
    const actionSpinnerNo = ref(false);
    const likeToBeSend = async (partnerUid: string) => {
      actionSpinnerYes.value = true;
      delete listOfLikes.value[partnerUid];
      await sendLike(partnerUid);
      closeModal();
    };

    const dislikeToBeSend = async (partnerUid: string) => {
      actionSpinnerNo.value = true;
      delete listOfLikes.value[partnerUid];
      await sendDislike(partnerUid, true);
      closeModal();
    };

    return {
      actionSpinnerYes,
      actionSpinnerNo,
      likeToBeSend,
      dislikeToBeSend,
      loadingProfile,
      profile,
      photos,
      closeModal,
      errorOccured,

      heart,
      closeOutline,
    };
  },
});
</script>

<style scoped>
</style>