
import { defineComponent } from "vue";
import {
  IonPage,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  isPlatform,
} from "@ionic/vue";
import {
  locationOutline,
  alertCircleOutline,
  personOutline,
  briefcaseOutline,
} from "ionicons/icons";

import { loadingListOfLikes, listOfLikes } from "@/components/likes";

import LikerProfileModal from "@/components/likes/LikerProfileModal.vue";
import LikesSkeleton from "@/components/likes/LikesSkeleton.vue";

export default defineComponent({
  name: "LikesTab",
  components: {
    LikesSkeleton,
    IonPage,
    IonHeader,
    IonIcon,
    IonToolbar,
    IonTitle,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    // loadLikes(); // called from Tabs.vue

    const openLikerProfile = async (uid: string) => {
      const modal = await modalController.create({
        component: LikerProfileModal,
        cssClass: isPlatform("desktop") ? "desktopModalClass" : "",
        componentProps: { uid: uid },
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      alertCircleOutline,
      openLikerProfile,
      locationOutline,
      listOfLikes,
      loadingListOfLikes,
      personOutline,
      briefcaseOutline,
    };
  },
});
